import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';

import { initDatadogRUM } from './datadog-loader';


// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function provideDatadog(): EnvironmentProviders {
	return makeEnvironmentProviders([
		{
			provide: APP_INITIALIZER,
			useFactory: initDatadogRUM,
			deps: [HttpClient],
			multi: true
		},
	]);
}
