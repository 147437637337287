import { ExperimentalFeature, isExperimentalFeatureEnabled } from '@datadog/browser-core';
import { getViewportDimension, initViewportObservable } from '../../browser/viewportObservable';
export function startDisplayContext(configuration) {
  var viewport;
  var animationFrameId;
  if (isExperimentalFeatureEnabled(ExperimentalFeature.DELAY_VIEWPORT_COLLECTION)) {
    // Use requestAnimationFrame to delay the calculation of viewport dimensions until after SDK initialization, preventing long tasks.
    animationFrameId = requestAnimationFrame(function () {
      viewport = getViewportDimension();
    });
  } else {
    viewport = getViewportDimension();
  }
  var unsubscribeViewport = initViewportObservable(configuration).subscribe(function (viewportDimension) {
    viewport = viewportDimension;
  }).unsubscribe;
  return {
    get: function () {
      return viewport ? {
        viewport: viewport
      } : undefined;
    },
    stop: function () {
      unsubscribeViewport();
      if (animationFrameId) {
        cancelAnimationFrame(animationFrameId);
      }
    }
  };
}
