<div *ngIf="testList?.length" class="test-list-panel" [class.visible]="isVisible" >
	<div class="test-list-header">
		<div class="first-row">
			<span class="close sl-typography-link-action" (click)="toggleVisibility()">Close</span>
		</div>
		<div class="second-row">
			<div class="stats ellipsisize">{{ includedTestsLength }} (out of {{ testList.length }}) tests included:</div>
			<mat-icon
				class="add clickable-icon"
				pTooltip="Add test"
				[ngClass]="{ 'disabled': isFinalList }"
				(click)="onShowAddTest()">
				add
			</mat-icon>
		</div>
		<div *ngIf="isAddTestField" class="third-row" >
			<form class="new-test-wrapper" [formGroup]="newTestForm" (ngSubmit)="onAddTest()">
				<input type="text" class="new-test" placeholder="Add test name" formControlName="testName"/>
				<span class="cancel sl-typography-link-action" (click)="isAddTestField = false">Cancel</span>
			</form>
			<div *ngIf="newTestForm.get('testName').errors?.duplicateEntries && isFormSubmitted" class="error-message">Test with this name already exists</div>
		</div>
		<div class="manual-test-item toggle-entire-list">
			<input
				type="checkbox"
				class="styled-checkbox clickable new-design"
				[disabled]="isFinalList"
				[ngClass]="{ 'part-selected': isTestListPartiallyIncluded }"
				[checked]="testList.length === includedTestsLength"
				(change)="onToggleAllTestsExclude()">
			<label class="toggleListLabel">Select all</label>
		</div>
	</div>
	<div class="drag-drop-list-container sl-fancy-scroll" #dragDropListContainer cdkScrollable>
		<ul cdkDropList class="drag-drop-list" [cdkDropListDisabled]="isFinalList" (cdkDropListDropped)="drop($event)">
			<li class="manual-test-item drag-drop-item checkbox-with-label-preview" *ngFor="let test of testList; let index = index" cdkDrag>
				<input
					type="checkbox"
					class="clickable styled-checkbox new-design"
					[disabled]="isFinalList"
					[checked]="!test.exclude"
					(change)="onToggleSpecificTestExclude(test)">
				<label class="test-name ellipsisize" [title]="test.name">{{ test.name }}</label>
			</li>
		</ul>
	</div>
	<div *ngIf="fromRecommendedTests && canLoadMore && !isFinalList" class="test-list-footer">
		<div class="load-more sl-typography-link-action" (click)="onLoadMoreClick()">Load more...</div>
	</div>
</div>
