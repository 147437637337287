<a
	class="header-button sl-flexbox sl-flexbox-align-center sl-flexbox-justify-center sl-flexbox-gap-4"
	[class.active-link]="isActiveRoute()"
	[class.header-button-icon]="iconSignal$()"
	[routerLink]="expandableSignal$() ? null : routeSignal$()"
	[queryParams]="queryParamsSignal$()">
	{{ textSignal$() }}
	@if (iconSignal$()) {
		<mat-icon class="material-symbols-outlined mat-icon-20">{{ iconSignal$() }}</mat-icon>
	}
	@if (tagSignal$()) {
		<sl-tag
			[label]="tagSignal$()"
			severity="attention"
			size="small">
		</sl-tag>
	}
	@if (expandableSignal$()) {
		<mat-icon class="mat-icon-20 expand-icon">expand_more</mat-icon>
	}
	@if (badgeSignal$()) {
		<span class="header-button-badge">{{ badgeSignal$() }}</span>
	}
</a>
