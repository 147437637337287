import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { inject, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, from, Observable, of, switchMap, tap } from 'rxjs';

import { LoaderService } from '@@core/services/loader.service';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';

import { AuthService } from '../../../features/auth/services/auth.service';
import { TOKEN_INTERCEPTOR_OPTIONS } from './token-interceptor.model';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
	readonly #redirectUrl = new BehaviorSubject<string>(null);
	readonly #router = inject(Router);
	readonly #loaderService = inject(LoaderService);
	readonly #injector = inject(Injector);
	readonly #tokenInterceptorOptions = inject(TOKEN_INTERCEPTOR_OPTIONS);
	readonly #authStore = inject(AuthStore);

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const authService = this.#injector.get(AuthService);
		this.#logDebugMessage('Interceptor triggered on url ' + request.urlWithParams);
		this.#loaderService.startBusy();

		if (this.#isExtendTokenRequest(request) || this.#isSSOLogoutRequest(request) || (!request.url.includes('/auth/') && !request.url.includes('assets/'))) {
			const token = this.#authStore.getAccessToken();
			this.#logDebugMessage('Token added');

			request = request.clone({
				setHeaders: {
					Authorization: `Bearer ${token}`
				}
			});
		}

		if (!(this.#isExtendTokenRequest(request) || this.#isSSOLogoutRequest(request) || request.url.includes('/auth/')) && !request.url.includes('assets/')) {
			void authService.startTokenExtendTimer();
		}

		return next.handle(request).pipe(
			tap(o => {
				if (o instanceof HttpResponse) {
					this.#loaderService.stopBusy();
					this.#redirectUrl.next(null);
				}
			}),
			catchError((error: HttpErrorResponse) => {
				this.#loaderService.stopBusy();
				if (!this.#handleAuthorizationError(error)) {
					if (!this.#redirectUrl.value) {
						return this.#handleAuthenticationError(error);
					}
				}
				throw new HttpErrorResponse(error); // TODO check if we should return throwError Observable instead of throwing error
			}) as any);
	}

	#handleAuthenticationError(err: HttpErrorResponse): Observable<any> {
		const authService = this.#injector.get(AuthService);
		if ((err.status === 401 || err.status === 403) && !(err.url?.includes('/auth/') && !(this.#isExtendTokenRequest(err) || this.#isSSOLogoutRequest(err)))) {
			const matches = this.#router.url.match(/\?redirect=(.+)$/);

			// In case the user is already in login page, e.g. when user credentials are invalid
			this.#redirectUrl.next(matches?.length === 2 ? matches[1] : this.#router.url);

			console.log('After successful login will redirect to:', this.#redirectUrl.value);
			authService.logout(true)
				.pipe(
					switchMap(() => from(this.#router.navigate(['/login'], { queryParams: { redirect: this.#redirectUrl.value } })))
				).subscribe();

			return of(false);
		}

		throw err;
	}

	#handleAuthorizationError(err: HttpErrorResponse): boolean {
		if (err.error?.error?.code === 3001 && err.status === 401) {
			console.error(`Unauthorized API call to ${err.url} with error message ${JSON.stringify(err.error)}`);

			return true;
		}

		return false;
	}

	#isExtendTokenRequest(request: HttpRequest<any> | HttpErrorResponse): boolean {
		// NOTE: If new API call endpoint is created for extend token, include a check bellow
		return request?.url?.endsWith('/auth/token/extend') || request?.url?.endsWith('/auth/sso/extend');
	}

	#isSSOLogoutRequest(request: HttpRequest<any> | HttpErrorResponse): boolean {
		return request?.url?.endsWith('/auth/sso/logout');
	}

	#logDebugMessage(message: any, otherMessages?: any): void {
		if (this.#tokenInterceptorOptions.debug === true) {
			console.log('[TOKEN INTERCEPTOR] ' + message);
		}
	}
}
