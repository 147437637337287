import { PageExitReason, runOnReadyState } from '@datadog/browser-core';
import { getSessionReplayLink } from '../domain/getSessionReplayLink';
export function createPostStartStrategy(configuration, lifeCycle, sessionManager, viewHistory, startRecordingImpl, getOrCreateDeflateEncoder) {
  var status = 0 /* RecorderStatus.Stopped */;
  lifeCycle.subscribe(9 /* LifeCycleEventType.SESSION_EXPIRED */, function () {
    if (status === 2 /* RecorderStatus.Starting */ || status === 3 /* RecorderStatus.Started */) {
      stop();
      status = 1 /* RecorderStatus.IntentToStart */;
    }
  });
  // Stop the recorder on page unload to avoid sending records after the page is ended.
  lifeCycle.subscribe(11 /* LifeCycleEventType.PAGE_EXITED */, function (pageExitEvent) {
    if (pageExitEvent.reason === PageExitReason.UNLOADING) {
      stop();
    }
  });
  lifeCycle.subscribe(10 /* LifeCycleEventType.SESSION_RENEWED */, function () {
    if (status === 1 /* RecorderStatus.IntentToStart */) {
      start();
    }
  });
  function start(options) {
    var session = sessionManager.findTrackedSession();
    if (canStartRecording(session, options)) {
      status = 1 /* RecorderStatus.IntentToStart */;
      return;
    }
    if (isRecordingInProgress(status)) {
      return;
    }
    status = 2 /* RecorderStatus.Starting */;
    runOnReadyState(configuration, 'interactive', function () {
      if (status !== 2 /* RecorderStatus.Starting */) {
        return;
      }
      var deflateEncoder = getOrCreateDeflateEncoder();
      if (!deflateEncoder) {
        status = 0 /* RecorderStatus.Stopped */;
        return;
      }
      ;
      stopRecording = startRecordingImpl(lifeCycle, configuration, sessionManager, viewHistory, deflateEncoder).stop;
      status = 3 /* RecorderStatus.Started */;
    });
    if (shouldForceReplay(session, options)) {
      sessionManager.setForcedReplay();
    }
  }
  function stop() {
    if (status !== 0 /* RecorderStatus.Stopped */ && status === 3 /* RecorderStatus.Started */) {
      stopRecording === null || stopRecording === void 0 ? void 0 : stopRecording();
    }
    status = 0 /* RecorderStatus.Stopped */;
  }
  var stopRecording;
  return {
    start: start,
    stop: stop,
    getSessionReplayLink: function () {
      return getSessionReplayLink(configuration, sessionManager, viewHistory, status !== 0 /* RecorderStatus.Stopped */);
    },
    isRecording: function () {
      return status === 3 /* RecorderStatus.Started */;
    }
  };
}
function canStartRecording(session, options) {
  return !session || session.sessionReplay === 0 /* SessionReplayState.OFF */ && (!options || !options.force);
}
function isRecordingInProgress(status) {
  return status === 2 /* RecorderStatus.Starting */ || status === 3 /* RecorderStatus.Started */;
}
function shouldForceReplay(session, options) {
  return options && options.force && session.sessionReplay === 0 /* SessionReplayState.OFF */;
}
