import { ExperimentalFeature, isExperimentalFeatureEnabled } from '../../tools/experimentalFeatures';
import { isEmptyObject } from '../../tools/utils/objectUtils';
import { objectEntries } from '../../tools/utils/polyfills';
import { dateNow } from '../../tools/utils/timeUtils';
import { generateAnonymousId } from '../user';
import { SESSION_EXPIRATION_DELAY, SESSION_TIME_OUT_DELAY } from './sessionConstants';
import { isValidSessionString, SESSION_ENTRY_REGEXP, SESSION_ENTRY_SEPARATOR } from './sessionStateValidation';
export var EXPIRED = '1';
export function getExpiredSessionState(previousSessionState) {
  var expiredSessionState = {
    isExpired: EXPIRED
  };
  if (isExperimentalFeatureEnabled(ExperimentalFeature.ANONYMOUS_USER_TRACKING)) {
    if (previousSessionState === null || previousSessionState === void 0 ? void 0 : previousSessionState.anonymousId) {
      expiredSessionState.anonymousId = previousSessionState === null || previousSessionState === void 0 ? void 0 : previousSessionState.anonymousId;
    } else {
      expiredSessionState.anonymousId = generateAnonymousId();
    }
  }
  return expiredSessionState;
}
export function isSessionInNotStartedState(session) {
  return isEmptyObject(session);
}
export function isSessionStarted(session) {
  return !isSessionInNotStartedState(session);
}
export function isSessionInExpiredState(session) {
  return session.isExpired !== undefined || !isActiveSession(session);
}
// An active session is a session in either `Tracked` or `NotTracked` state
function isActiveSession(sessionState) {
  // created and expire can be undefined for versions which was not storing them
  // these checks could be removed when older versions will not be available/live anymore
  return (sessionState.created === undefined || dateNow() - Number(sessionState.created) < SESSION_TIME_OUT_DELAY) && (sessionState.expire === undefined || dateNow() < Number(sessionState.expire));
}
export function expandSessionState(session) {
  session.expire = String(dateNow() + SESSION_EXPIRATION_DELAY);
}
export function toSessionString(session) {
  return objectEntries(session)
  // we use `aid` as a key for anonymousId
  .map(function (_a) {
    var key = _a[0],
      value = _a[1];
    return key === 'anonymousId' ? "aid=".concat(value) : "".concat(key, "=").concat(value);
  }).join(SESSION_ENTRY_SEPARATOR);
}
export function toSessionState(sessionString) {
  var session = {};
  if (isValidSessionString(sessionString)) {
    sessionString.split(SESSION_ENTRY_SEPARATOR).forEach(function (entry) {
      var matches = SESSION_ENTRY_REGEXP.exec(entry);
      if (matches !== null) {
        var key = matches[1],
          value = matches[2];
        if (key === 'aid') {
          // we use `aid` as a key for anonymousId
          session.anonymousId = value;
        } else {
          session[key] = value;
        }
      }
    });
  }
  return session;
}
