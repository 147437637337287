export interface DatadogConfig {
	applicationId: string;
	clientToken: string;
}

export enum DatadogEnv {
	INTEGRATION = 'integration',
	STAGING = 'staging',
	PRODUCTION = 'production'
}
