import { HttpClient } from '@angular/common/http';
import { datadogRum } from '@datadog/browser-rum';
import { firstValueFrom, map } from 'rxjs';

import { isLocalHost, isNullUndefinedOrEmptyObject } from '@@core/helpers/core.helper';
import { BaseUserData } from '@@core/models/auth/auth-model';
import { SLLocalStorageKey } from '@@shared/local-storage/models/SLTableStorageKey';

import { DatadogConfig, DatadogEnv } from './datadog.models';

const INTEGRATIONS = 'integrations.json';

/**
 * Initializes Datadog RUM (Real User Monitoring) for the application.
 *
 * This function checks if the application is running on localhost and skips
 * the initialization if true. Otherwise, it retrieves the environment and
 * Datadog configuration, validates the configuration, and proceeds with
 * the Datadog initialization.
 *
 * @param http - The HttpClient instance used for making HTTP requests.
 * @returns A function that returns a Promise which resolves when the
 *          initialization is complete.
 */
export const initDatadogRUM = (http: HttpClient): () => Promise<void> => {
	if (isLocalHost()) {
		console.log('[RUM] Localhost detected, skipping initialization');
		return () => Promise.resolve();
	}

	const hostname: string = window.location.hostname;
	const env: DatadogEnv = getEnv(hostname);
	const datadogConfigFromLS: DatadogConfig = getDataDogConfigFromLS();
	const isDatadogConfigValid: boolean = validateDatadogConfig(datadogConfigFromLS);

	return handleDatadogInitialization(isDatadogConfigValid, datadogConfigFromLS, env, http);
};

/**
 * Terminates the Datadog client by stopping session replay recording, stopping the session,
 * clearing the user, and clearing the global context. Optionally logs the reason for termination.
 *
 * @param [reason=null] - The reason for terminating the Datadog client.
 * If provided, it will be included in the log message.
 *
 */
export const terminateDatadogClient = (reason: string = null): void => {
	datadogRum.stopSessionReplayRecording();
	datadogRum.stopSession();
	datadogRum.clearUser();
	datadogRum.clearGlobalContext();
	console.log(`[RUM] terminated${reason ? ` due to: ${reason}` : ''}`);
};

/**
 * Sets the Datadog user context with the provided user information.
 *
 * @param user - The user data object containing user information.
 * @param user.email - The email of the user.
 * @param user.name - The name of the user.
 *
 * @returns
 */
export const setDatadogUserContext = (user: BaseUserData): void => {
	const isClientInitialized: boolean = isDatadogInitialized();
	if (!isClientInitialized) {
		console.warn('[RUM] Datadog RUM client is not initialized. Skipping user context setup.');
		return;
	}
	datadogRum.setUser({
		id: user?.email,
		email: user?.email,
		name: user?.name
	});
	console.log('[RUM] User context set');
};

/**
 * Checks if Datadog is initialized.
 *
 * @returns {boolean} `true` if Datadog is initialized, otherwise `false`.
 */
export const isDatadogInitialized = (): boolean => !!datadogRum.getInitConfiguration();

const handleDatadogInitialization = (isDatadogConfigValid: boolean, config: DatadogConfig, env: DatadogEnv, http: HttpClient): () => Promise<void> => {
	if (isDatadogConfigValid) {
		registerDatadogClient(config, env);
		return () => Promise.resolve();
	} else {
		localStorage.removeItem(SLLocalStorageKey.DATADOG_RUM);
		return async (): Promise<void> => {
			await fetchAndRegisterDatadog(http, env);
		};
	}
};

const getDataDogConfigFromLS = (): DatadogConfig => JSON.parse(localStorage.getItem(SLLocalStorageKey.DATADOG_RUM)) as DatadogConfig;

const validateDatadogConfig = (datadogConfig: DatadogConfig): boolean => !isNullUndefinedOrEmptyObject(datadogConfig) && !!(datadogConfig.clientToken && datadogConfig.applicationId);

const fetchAndRegisterDatadog = async (http: HttpClient, env: DatadogEnv): Promise<void> => {
	try {
		const res = await firstValueFrom(http.get<{ rum: DatadogConfig }>(INTEGRATIONS).pipe(
			map((response) => response.rum)
		));
		localStorage.setItem(SLLocalStorageKey.DATADOG_RUM, JSON.stringify(res));
		registerDatadogClient(res, env);
	} catch (err) {
		terminateDatadogClient('Error fetching integrations.json');
	}
};

const registerDatadogClient = (datadogConfig: DatadogConfig, env: DatadogEnv): void => {
	const shouldBlock: boolean = shouldBlockRegistration(datadogConfig);
	if (shouldBlock) {
		return;
	}

	const { clientToken, applicationId } = datadogConfig;

	const sessionReplaySampleRate = env === DatadogEnv.PRODUCTION ? 20 : 100;

	datadogRum.init({
		applicationId,
		clientToken,
		site: 'datadoghq.com',
		service: 'sl-web-app',
		env,
		version: '1.0.0',
		sessionSampleRate: 100,
		sessionReplaySampleRate,
		trackUserInteractions: false,
		trackResources: true,
		trackLongTasks: true,
		defaultPrivacyLevel: 'mask-user-input'
	});
	console.log('[RUM] initialized');
};

const shouldBlockRegistration = (datadogConfig: DatadogConfig): boolean => {
	const blockRegistration = isLocalHost() || isNullUndefinedOrEmptyObject(datadogConfig);
	if (blockRegistration) {
		return true;
	}
	const internalContext = datadogRum.getInternalContext();

	if (internalContext && internalContext.application_id) {
		return true;
	}
	return false;
};

const getEnv = (url: string): DatadogEnv => {
	if (url.includes(DatadogEnv.INTEGRATION)) {
		return DatadogEnv.INTEGRATION;
	} else if (url.includes(DatadogEnv.STAGING)) {
		return DatadogEnv.STAGING;
	} else {
		return DatadogEnv.PRODUCTION;
	}
};



