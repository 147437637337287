import { Routes } from '@angular/router';

import { canActivateAccessTokenGuard } from '@@core/guards/access-token.guard';
import { orderedAsyncCanActivate } from '@@core/guards/async-guards.fns';
import { ldFeatureMatchGuard } from '@@core/guards/ld-feature-match.guard';
import { redirectTo404 } from '@@core/guards/redirect-to-404.guard';
import { roleMatchGuard } from '@@core/guards/role-match.guard';
import { canActivateSSOTokenGuard } from '@@core/guards/sso-token.guard';
import { UserPilotExperience } from '@@core/models/userpilot.model';
import { LDFeatureKey } from '@@core/services/launch-darkly.service';

import { AuthenticatedLayerComponent } from './authenticated-layer/authenticated-layer.component';
import { UserRole, UserRolePermission } from './core/models/auth/auth-model';
import { canActivateAuthGuard } from './features/auth/services/auth-guard';
import { UnauthenticatedLayerComponent } from './unauthenticated-layer/unauthenticated-layer.component';

const applicationRoutes: Routes = [
	{
		path: 'login',
		title: 'Login - Sealights',
		component: UnauthenticatedLayerComponent,
		children: [
			{
				path: '',
				loadComponent: () => import('./features/auth/features/login/components/login/login.component').then(c => c.LoginComponent)
			},
		],
	},
	{
		path: 'forgot',
		title: 'Forgot Password - Sealights',
		component: UnauthenticatedLayerComponent,
		children: [
			{
				path: '',
				loadComponent: () => import('./features/auth/features/forgot-password/components/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent)
			}
		]
	},
	{
		path: 'temporary-password-change',
		title: 'Change Temporary Password - Sealights',
		component: UnauthenticatedLayerComponent,
		children: [
			{
				path: '',
				loadComponent: () => import('./features/auth/features/temporary-password-change/components/temporary-password-change/temporary-password-change.component').then(c => c.TemporaryPasswordChangeComponent)
			}
		]
	},
	{
		path: '',
		component: AuthenticatedLayerComponent,
		canActivate: [orderedAsyncCanActivate([canActivateSSOTokenGuard, canActivateAccessTokenGuard, canActivateAuthGuard])],
		children: [
			{
				path: '404',
				title: 'Error - Sealights',
				loadComponent: () => import('./core/components/not-found-page/not-found-page.component').then(c => c.NotFoundPageComponent)
			},
			{
				path: 'dashboard',
				loadChildren: () => import('./features/dashboard/dashboard-routing').then(m => m.DASHBOARD_ROUTES),
			},
			{
				path: 'build',
				loadChildren: () => import('./features/build/build-routing').then(m => m.BUILD_ROUTES),
			},
			{
				path: 'coverage',
				loadChildren: () => import('./features/coverage/coverage-routing').then(m => m.COVERAGE_ROUTES),
			},
			{
				path: 'tga',
				loadChildren: () => import('./features/tga/tga-routing').then(m => m.TGA_ROUTES),
			},
			{
				path: 'tia/v3',
				loadChildren: () => import('./features/tia/tia-routing').then(m => m.TIA_ROUTES),
			},
			{
				path: 'settings',
				loadChildren: () => import('./features/app-settings/app-settings-routing').then(m => m.APP_SETTINGS_ROUTES),
			},
			{
				path: 'cockpit',
				loadChildren: () => import('./features/cockpit/cockpit-routing').then(m => m.COCKPIT_ROUTES),
				canActivate: [roleMatchGuard],
				data: {
					roles: UserRolePermission.COCKPIT
				}
			},
			{
				path: 'lab-live-monitor',
				loadComponent: () => import('./features/cockpit/lab-live-monitor/components/lab-live-monitor-root/lab-live-monitor-root.component').then(m => m.LabLiveMonitorRootComponent),
				canActivate: [roleMatchGuard],
			},
			{
				path: 'doc',
				loadChildren: () => import('./features/doc/doc-routing').then(m => m.DOC_ROUTES),
				canActivate: [ldFeatureMatchGuard],
				data: {
					features: [LDFeatureKey.PUBLIC_API]
				}
			},
			{
				path: 'integrations',
				loadChildren: () => import('./features/slack-integration/slack-integration-routing').then(m => m.SLACK_ROUTES),
				canActivate: [roleMatchGuard, ldFeatureMatchGuard],
				data: {
					roles: [UserRole.UserDevops],
					features: [LDFeatureKey.SLACK_INTEGRATION]
				},
			},
			{
				path: 'quality-analytics',
				loadChildren: () => import('./features/quality-analytics/trend-report/trend-report-routing').then(m => m.QUALITY_ANALYTICS_ROUTES),
				data: {
					userPilotExperience: UserPilotExperience.QualityAnalytics,
				}
			},
			// {
			// 	path: 'troubleshooting',
			// 	loadChildren: () => import('./features/troubleshooting/troubleshooting-routing').then(m => m.TROUBLESHOOTING_ROUTES),
			// 	canActivate: [ldFeatureMatchGuard],
			// 	data: {
			// 		features: [LDFeatureKey.TROUBLESHOOTING],
			// 	}
			// },
			{
				path: 'custom-tab',
				loadChildren: () => import('./features/custom-tab/custom-tab-routing').then(m => m.CUSTOM_TAB_ROUTES),
				canActivate: [roleMatchGuard, ldFeatureMatchGuard],
				data: {
					features: [LDFeatureKey.CUSTOM_TABS]
				}
			}
		]
	},
	{
		path: '**',
		loadComponent: () => import('./core/components/not-found-page/not-found-page.component').then(c => c.NotFoundPageComponent),
		canActivate: [redirectTo404],
	}
];

export default applicationRoutes;
