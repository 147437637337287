import { sanitize } from '../../tools/serialisation/sanitize';
import { jsonStringify } from '../../tools/serialisation/jsonStringify';
import { computeStackTrace } from '../../tools/stackTrace/computeStackTrace';
import { toStackTraceString } from '../../tools/stackTrace/handlingStack';
export var NO_ERROR_STACK_PRESENT_MESSAGE = 'No stack, consider using an instance of Error';
export function computeRawError(_a) {
  var stackTrace = _a.stackTrace,
    originalError = _a.originalError,
    handlingStack = _a.handlingStack,
    startClocks = _a.startClocks,
    nonErrorPrefix = _a.nonErrorPrefix,
    source = _a.source,
    handling = _a.handling;
  var isErrorInstance = isError(originalError);
  var message = computeMessage(stackTrace, isErrorInstance, nonErrorPrefix, originalError);
  var stack = hasUsableStack(isErrorInstance, stackTrace) ? toStackTraceString(stackTrace) : NO_ERROR_STACK_PRESENT_MESSAGE;
  var causes = isErrorInstance ? flattenErrorCauses(originalError, source) : undefined;
  var type = stackTrace ? stackTrace.name : undefined;
  var fingerprint = tryToGetFingerprint(originalError);
  return {
    startClocks: startClocks,
    source: source,
    handling: handling,
    handlingStack: handlingStack,
    originalError: originalError,
    type: type,
    message: message,
    stack: stack,
    causes: causes,
    fingerprint: fingerprint
  };
}
function computeMessage(stackTrace, isErrorInstance, nonErrorPrefix, originalError) {
  // Favor stackTrace message only if tracekit has really been able to extract something meaningful (message + name)
  // TODO rework tracekit integration to avoid scattering error building logic
  return (stackTrace === null || stackTrace === void 0 ? void 0 : stackTrace.message) && (stackTrace === null || stackTrace === void 0 ? void 0 : stackTrace.name) ? stackTrace.message : !isErrorInstance ? "".concat(nonErrorPrefix, " ").concat(jsonStringify(sanitize(originalError))) : 'Empty message';
}
function hasUsableStack(isErrorInstance, stackTrace) {
  if (stackTrace === undefined) {
    return false;
  }
  if (isErrorInstance) {
    return true;
  }
  // handle cases where tracekit return stack = [] or stack = [{url: undefined, line: undefined, column: undefined}]
  // TODO rework tracekit integration to avoid generating those unusable stack
  return stackTrace.stack.length > 0 && (stackTrace.stack.length > 1 || stackTrace.stack[0].url !== undefined);
}
export function tryToGetFingerprint(originalError) {
  return isError(originalError) && 'dd_fingerprint' in originalError ? String(originalError.dd_fingerprint) : undefined;
}
export function getFileFromStackTraceString(stack) {
  var _a;
  return (_a = /@ (.+)/.exec(stack)) === null || _a === void 0 ? void 0 : _a[1];
}
export function isError(error) {
  return error instanceof Error || Object.prototype.toString.call(error) === '[object Error]';
}
export function flattenErrorCauses(error, parentSource) {
  var currentError = error;
  var causes = [];
  while (isError(currentError === null || currentError === void 0 ? void 0 : currentError.cause) && causes.length < 10) {
    var stackTrace = computeStackTrace(currentError.cause);
    causes.push({
      message: currentError.cause.message,
      source: parentSource,
      type: stackTrace === null || stackTrace === void 0 ? void 0 : stackTrace.name,
      stack: stackTrace && toStackTraceString(stackTrace)
    });
    currentError = currentError.cause;
  }
  return causes.length ? causes : undefined;
}
