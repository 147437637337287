import { Component, inject, OnInit, Renderer2 } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { getState } from '@ngrx/signals';
import { Store } from '@ngrx/store';
import { distinctUntilChanged, filter, take, tap } from 'rxjs';
import { Userpilot } from 'userpilot';

import { configureJiraWidget, hashWithSha256, removeJiraWidget } from '@@core/helpers/core.helper';
import { LDContext } from '@@core/models/auth/auth-model';
import { AnalyticsService } from '@@core/services/analytics/analytics.service';
import { FeatureService } from '@@core/services/features.service';
import { HotjarService } from '@@core/services/hotjar.service';
import { LaunchDarklyService, LDFeatureKey } from '@@core/services/launch-darkly.service';
import { SwUpdateService } from '@@core/services/sw-update.service';
import { UserPilotService } from '@@core/services/user-pilot.service';
import { User } from '@@settings/model/permissions.model';
import { PreventDialogCloseOnNavigation } from '@@shared/dialog';
import { isDatadogInitialized, setDatadogUserContext, terminateDatadogClient } from '@@shared/providers/datadog/datadog-loader';
import { selectLoginAsUserId } from '@@shared/stores/app-stores/global-store/selectors/global-store.selectors';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';
import { ConfigurationStore } from '@@shared/stores/configuration-store/stores/configuration.store';

@Component({
	selector: 'sl-root',
	template: '<router-outlet />',
	styleUrl: './app.component.scss',
	standalone: true,
	imports: [RouterOutlet]
})
export class AppComponent implements OnInit {
	readonly #impersonatedPageClass = 'impersonated-page';
	readonly #integrationsSignal$ = inject(AuthStore).integrations;
	readonly #isAuthenticatedSignal$ = inject(AuthStore).isAuthenticated;
	readonly #isAuthenticated$ = toObservable(inject(AuthStore).isAuthenticated);
	readonly #isLdServiceInitializedSignal$ = inject(LaunchDarklyService).ldInitializedSignal$;
	readonly #isImpersonateUserLogin$ = inject(Store).select(selectLoginAsUserId);
	readonly #swUpdateService = inject(SwUpdateService);
	readonly #renderer = inject(Renderer2);
	readonly #dialogService = inject(MatDialog);
	readonly #authStore = inject(AuthStore);
	readonly #featureService = inject(FeatureService);
	readonly #launchDarklyService = inject(LaunchDarklyService);
	readonly #configurationStore = inject(ConfigurationStore);
	readonly #hotjarService = inject(HotjarService);
	readonly #userPilotService = inject(UserPilotService);
	readonly #analyticsService = inject(AnalyticsService);

	constructor() {
		inject(Router).events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				Userpilot.reload();
			}
			for (const dialog of this.#dialogService.openDialogs) {
				if (dialog?.componentInstance?.preventCloseOnNavigation) {
					continue;
				}
				dialog.close();
			}
		});

		this.#subscribeToAuthenticationChanges();
	}

	ngOnInit(): void {
		void this.#swUpdateService.init();
	}

	#subscribeToAuthenticationChanges(): void {
		this.#isAuthenticated$
			.pipe(
				distinctUntilChanged(),
				tap(() => {
					this.#handleExternalLibraries();
					this.#handleLaunchDarklyService();
					this.#handleImpersonateBodyClass();
				})
			)
			.subscribe();
	}

	#handleImpersonateBodyClass(): void {
		if (this.#isAuthenticatedSignal$()) {
			this.#isImpersonateUserLogin$
				.pipe(
					tap(isImpersonateUserLogin => {
						if (isImpersonateUserLogin) {
							this.#renderer.addClass(document.body, this.#impersonatedPageClass);
						} else {
							this.#renderer.removeClass(document.body, this.#impersonatedPageClass);
						}
					})
				)
				.subscribe();
		} else {
			this.#renderer.removeClass(document.body, this.#impersonatedPageClass);
		}
	}

	#handleExternalLibraries(): void {
		const authState = getState(this.#authStore);
		if (this.#isAuthenticatedSignal$()) {
			hashWithSha256(authState.user?.email)
				.then(identifier => Userpilot.identify(identifier))
				.catch(() => Userpilot.anonymous());

			configureJiraWidget({
				fieldValues: {
					fullName: this.#authStore.fullName(),
					email: authState.user?.email
				}
			});
		} else {
			Userpilot.anonymous();
			removeJiraWidget();
			globalThis.Intercom?.('shutdown');
		}
	}

	#handleLoadingCustomerConfig(): void {
		if (this.#isAuthenticatedSignal$()) {
			this.#configurationStore.loadCustomerConfig();
		} else {
			this.#configurationStore.reset();
		}
	}

	#handleLaunchDarklyService(): void {
		if (this.#isAuthenticatedSignal$()) {
			this.#startLaunchDarklyInitialization();
		} else {
			this.#launchDarklyService.shutdown();
		}
	}

	#startLaunchDarklyInitialization(): void {
		if (this.#integrationsSignal$()?.ld) {
			this.#setupLaunchDarklyClient(this.#integrationsSignal$().ld?.context, this.#integrationsSignal$().ld?.hash);
		}
	}

	#setupLaunchDarklyClient(context: LDContext, hash: string): void {
		if (this.#isLdServiceInitializedSignal$()) {
			this.#setupDependencies();
		} else {
			this.#setUpLaunchDarklyAndDependencies(context, hash);
		}
	}

	#setUpLaunchDarklyAndDependencies(context: LDContext, hash: string): void {
		let impersonatedContext: LDContext = null;
		const isImpersonationMode: boolean = !!localStorage.getItem('impersonatedUserId');
		if (isImpersonationMode) {
			const impersonatedUserData: User = JSON.parse(localStorage.getItem('userData')) as User;
			impersonatedContext = {
				...context,
				role: impersonatedUserData.role,
				email: impersonatedUserData.email
			};
		}
		const finalContext = isImpersonationMode ? impersonatedContext : context;
		this.#launchDarklyService.initialize(finalContext, hash)
			.pipe(
				filter((isInitialized) => !!isInitialized),
				take(1),
				tap(() => this.#setupDependencies())
			).subscribe();
	}

	#setupDependencies(): void {
		this.#handleLoadingCustomerConfig();
		this.#handleHotJatToggle();
		this.#handleUserPilotToggle();
		this.#handleAnalyticsToggle();
		this.#handleDataDogToggle();
	}

	#handleHotJatToggle(): void {
		this.#featureService.isFeatureOn(LDFeatureKey.HOTJAR)
			.pipe(
				tap((hotJar) => this.#hotjarService.setHotjar(hotJar))
			).subscribe();
	}

	#handleUserPilotToggle(): void {
		this.#featureService.isFeatureOn(LDFeatureKey.USER_PILOT)
			.pipe(
				tap((userPilot) => this.#userPilotService.init(userPilot))
			).subscribe();
	}

	#handleAnalyticsToggle(): void {
		this.#featureService.isFeatureOn(LDFeatureKey.USAGE_ANALYTICS)
			.pipe(
				tap((analyticsReq) => {
					if (analyticsReq) {
						this.#analyticsService.init();
					}
				})
			).subscribe();
	}

	/**
	 * Handles the toggling of the DataDog RUM feature.
	 *
	 * DataDog RUM is initialized on application start with anonymous user information.
	 * This method checks if the DataDog RUM feature is enabled.
	 * If the feature is disabled, it terminates the DataDog client.
	 * If the feature is enabled and the user is authenticated, it sets the user information in DataDog RUM using the authentication state.
	 *
	 * @private
	 * @returns {void}
	 */
	#handleDataDogToggle(): void {
		this.#featureService.isFeatureOn(LDFeatureKey.DATADOG_RUM)
			.pipe(
				tap((isOn) => {
					if (!isOn) {
						terminateDatadogClient('Feature turned off');
						return;
					}
					const isClientInitialized: boolean = isDatadogInitialized();
					if (!isClientInitialized) {
						return;
					}
					const authState = getState(this.#authStore);
					setDatadogUserContext(authState.user);
				})
			).subscribe();
	}
}
