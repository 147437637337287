export enum TrackActionType {
	ClickExternalLink = 'click_external_link',
	ClickAgentReleaseNotesPage = 'click_Agent_Release_Notes_Page',
	ClickInfoMenu = 'click_?_menu',
	ClickSealightsApiReferencePage = 'click_SeaLights_API_Reference_page',
	ClickWhatIsNewPage = 'click_What\'s_New_page',
	ClickSealightsLearningCenterPage = 'click_SeaLights_Learning_Center_page',
	ClickSealightsDocumentationPage = 'click_SeaLights_Documentation_page',
	ClickTricentisAcademyPortalPage = 'click_Tricentis_Academy_Portal_page',
	ClickSupportPortalPage = 'click_Support_Portal_page',
	ClickProductTourPage = 'click_Product_Tour_page',
	ClickPrivacyPolicyPage = 'click_Privacy_Policy_page',
	ClickTermsOfUsePage = 'click_Terms_of_Use_page',
	ClickNewManualTest = 'tia_click_new_manual_test'
}

export interface ClickExternalLinkPayloadContext {
	name: string;
}

export type AnalyticsPayloadContextProvider<T> = () => T;
