import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, DestroyRef, inject, output, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { Router } from '@angular/router';
import { catchError, delay, EMPTY, tap } from 'rxjs';

import { MatchPasswordsValidator } from '@@core/validators/match-password.validator';
import { zxcvbnValidator } from '@@core/validators/zxcvbn.validator';
import { ChangePasswordService } from '@@shared/change-password/services/change-password.service';
import { ButtonComponent } from '@@shared/design/components/button/button.component';
import { TextButtonComponent } from '@@shared/design/components/text-button/text-button.component';
import { APP_CONFIG } from '@@shared/providers/application-config-provider/application-config-provider.model';

@Component({
	selector: 'sl-change-password',
	templateUrl: './change-password.component.html',
	styleUrl: './change-password.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		MatIconModule,
		MatFormFieldModule,
		ReactiveFormsModule,
		MatInputModule,
		TextButtonComponent,
		ButtonComponent
	],
	providers: [ChangePasswordService]
})
export class ChangePasswordComponent {
	readonly closeIt = output<boolean>();

	readonly passwordValidationRules = [Validators.compose([Validators.required, ...this.#getPasswordValidationRules(), zxcvbnValidator(3)])];
	readonly changePasswordForm: FormGroup = new FormGroup({
		oldPassword: new FormControl<string>('', Validators.required),
		newPassword: new FormControl<string>('', this.passwordValidationRules),
		confirmPassword: new FormControl<string>('', this.passwordValidationRules)
	}, { validators: MatchPasswordsValidator('newPassword', 'confirmPassword') });

	readonly changePasswordStoreSignal$ = signal({
		successMessage: false,
		wrongOldPassword: false,
		serverError: false
	});

	readonly validationRules = inject(APP_CONFIG)?.auth?.cognitoPolicy;
	readonly #changePasswordService = inject(ChangePasswordService);
	readonly #router = inject(Router);
	readonly #destroyRef = inject(DestroyRef);

	closeDialog(): void {
		this.closeIt.emit(true);
	}

	changePassword(): void {
		this.changePasswordStoreSignal$.update(state => ({
			...state,
			wrongOldPassword: false,
			serverError: false
		}));

		this.#changePasswordService.changePassword(this.changePasswordForm.value.oldPassword, this.changePasswordForm.value.newPassword)
			.pipe(
				takeUntilDestroyed(this.#destroyRef),
				tap(() => {
					this.changePasswordStoreSignal$.update(state => ({
						...state,
						successMessage: true
					}));
					this.closeDialog();
				}),
				delay(1),
				tap(() => void this.#router.navigateByUrl('/login')),
				catchError((error: HttpErrorResponse) => {

					const status = (error).status;

					if (status === 409) {
						this.changePasswordStoreSignal$.update(state => ({
							...state,
							wrongOldPassword: true
						}));
					} else {
						this.changePasswordStoreSignal$.update(state => ({
							...state,
							serverError: true
						}));
					}

					return EMPTY;
				})
			)
			.subscribe();
	}

	#getPasswordValidationRules(): ValidatorFn[] {
		const validators: ValidatorFn[] = [];
		const validationRules = inject(APP_CONFIG)?.auth?.cognitoPolicy;
		if (validationRules) {
			for (const rule of validationRules) {
				validators.push(this.#createRule(rule.key, rule.regex, rule.rule));
			}
		}

		return validators;
	}

	#createRule(ruleKey: string, regex: string, message: string): ValidatorFn {
		const fn: ValidatorFn = (c: AbstractControl) => {
			const res: ValidationErrors = {};
			if (Validators.pattern(regex)(c)) {
				res[ruleKey] = { error: message };

				return res;
			}

			return null;
		};

		return fn;
	}
}
