import { instrumentMethod, Observable } from '@datadog/browser-core';
export function createWindowOpenObservable() {
  var observable = new Observable();
  var stop = instrumentMethod(window, 'open', function () {
    return observable.notify();
  }).stop;
  return {
    observable: observable,
    stop: stop
  };
}
