import { elapsed } from '@datadog/browser-core';
import { waitPageActivityEnd } from '../../waitPageActivityEnd';
import { trackFirstHidden } from './trackFirstHidden';
export function trackLoadingTime(lifeCycle, domMutationObservable, windowOpenObservable, configuration, loadType, viewStart, callback) {
  var isWaitingForLoadEvent = loadType === "initial_load" /* ViewLoadingType.INITIAL_LOAD */;
  var isWaitingForActivityLoadingTime = true;
  var loadingTimeCandidates = [];
  var firstHidden = trackFirstHidden(configuration);
  function invokeCallbackIfAllCandidatesAreReceived() {
    if (!isWaitingForActivityLoadingTime && !isWaitingForLoadEvent && loadingTimeCandidates.length > 0) {
      var loadingTime = Math.max.apply(Math, loadingTimeCandidates);
      if (loadingTime < firstHidden.timeStamp) {
        callback(loadingTime);
      }
    }
  }
  var stop = waitPageActivityEnd(lifeCycle, domMutationObservable, windowOpenObservable, configuration, function (event) {
    if (isWaitingForActivityLoadingTime) {
      isWaitingForActivityLoadingTime = false;
      if (event.hadActivity) {
        loadingTimeCandidates.push(elapsed(viewStart.timeStamp, event.end));
      }
      invokeCallbackIfAllCandidatesAreReceived();
    }
  }).stop;
  return {
    stop: function () {
      stop();
      firstHidden.stop();
    },
    setLoadEvent: function (loadEvent) {
      if (isWaitingForLoadEvent) {
        isWaitingForLoadEvent = false;
        loadingTimeCandidates.push(loadEvent);
        invokeCallbackIfAllCandidatesAreReceived();
      }
    }
  };
}
