import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { APP_INITIALIZER, enableProdMode, importProvidersFrom } from '@angular/core';
import { provideNativeDateAdapter } from '@angular/material/core';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { bootstrapApplication, BrowserModule, Title } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { provideEffects } from '@ngrx/effects';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
import { provideStore, Store } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { firstValueFrom } from 'rxjs';

import { environmentInit } from '@@core/helpers/core.helper';
import { environment } from '@@environments/environment';
import { DataTestidDirective } from '@@shared/data-test-id-directive';
import { ImpersonateInterceptor } from '@@shared/interceptors';
import { provideApplicationConfig } from '@@shared/providers/application-config-provider/application-config.provider';
import { ApplicationConfig } from '@@shared/providers/application-config-provider/application-config-provider.model';
import { provideCustomMaterialIcons } from '@@shared/providers/custom-material-icons-provider/custom-material-icons.provider';
import { provideDatadog } from '@@shared/providers/datadog/datadog.provider';
import { provideTokenInterceptor } from '@@shared/providers/token-interceptor-provider/token-interceptor.provider';
import { GlobalStoreEffects, globalStoreReducer } from '@@shared/stores';
import * as GlobalStoreActions from '@@shared/stores/app-stores/global-store/actions/global-store.actions';
import { AuthStore } from '@@shared/stores/auth-store/stores/auth.store';
import { searchReducer } from '@@shared/stores/search-store/reducers/search.reducers';
import { userPreferencesOptionsStoreReducer } from '@@shared/user-preferences';
import { UserPreferencesEffects } from '@@shared/user-preferences/effects/user-preferences.effects';
import { userPreferencesStoreReducer } from '@@shared/user-preferences/reducers/user-preferences.reducers';

import { AppComponent } from './app/app.component';
import applicationRoutes from './app/app-routing';
import { AuthService } from './app/features/auth/services/auth.service';


export const initImpersonatedUser = (store: Store) => (): Promise<void> => new Promise(resolve => {
	store.dispatch(GlobalStoreActions.globalStoreLoadImpersonatedUserId());

	return resolve();
});

export const initAuthState = (authStore, authService: AuthService) => (): Promise<void> => new Promise(resolve => {
	if (authStore.restore()) {
		setTimeout(() => resolve());
	} else {
		firstValueFrom(authService.logout())
			.then(() => resolve())
			.catch(err => console.error(err));
	}
});


fetch(environment.configFile)
	.then((response) => response.json())
	.then((response: ApplicationConfig) => ({
		...response,
		slackIntegrationURL: response.slackIntegrationURL.replace('{{host}}', window.location.origin)
	}))
	.then((config: ApplicationConfig) => {
		environmentInit();
		if (environment.production) {
			enableProdMode();
		}

		return bootstrapApplication(AppComponent, {
			providers: [
				importProvidersFrom(
					BrowserModule,
					MatDialogModule,
					MatSnackBarModule,
					OverlayModule,
				),
				provideNativeDateAdapter(),
				provideCustomMaterialIcons(),
				provideServiceWorker('ngsw-worker.js', { enabled: environment.production }),
				provideStore({
					router: routerReducer,
					globalStore: globalStoreReducer,
					userPreferencesStore: userPreferencesStoreReducer,
					userPreferencesOptionsStore: userPreferencesOptionsStoreReducer,
					searchStore: searchReducer,
				}, {}),
				provideEffects([GlobalStoreEffects, UserPreferencesEffects]),
				provideRouterStore(),
				provideStoreDevtools({
					maxAge: 25,
					logOnly: environment.production,
					autoPause: true, // Pauses recording actions and state changes when the extension window is not open
					connectInZone: true
				}),
				provideApplicationConfig({ config }),
				{
					provide: APP_INITIALIZER,
					useFactory: initAuthState,
					deps: [AuthStore, AuthService],
					multi: true
				},
				{
					provide: APP_INITIALIZER,
					useFactory: initImpersonatedUser,
					deps: [Store],
					multi: true
				},
				provideDatadog(),
				provideTokenInterceptor(),
				{
					provide: HTTP_INTERCEPTORS,
					useClass: ImpersonateInterceptor,
					multi: true
				},
				{
					provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {
						closeOnNavigation: true,
						hasBackdrop: true
					}
				},
				Title,
				DataTestidDirective,
				provideAnimations(),
				provideHttpClient(withInterceptorsFromDi()),
				provideRouter(applicationRoutes, withComponentInputBinding())
			]
		});
	}).catch(err => console.error(err));
